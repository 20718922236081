.video-window {
  background: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  padding-top: 50px;
  display: flex;
  flex-direction: column;

  .btn-exit {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 30px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    padding-top: 20px;
  }
}