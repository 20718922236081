.selector-window {
  background: #FAFAFA;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  padding-top: 50px;
  display: flex;
  flex-direction: column;

  .btn-exit {
    position: absolute;
    top: 50px;
    right: 30px;
    font-size: 30px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .tendencia {
    border: 1px solid black;
    border-radius: 5px;
    padding: 15px;
    margin-top: 50px;
  }

  .tendencia-container {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
  }

  .tendencia-color-container {
    height: 10px;
    width: 100%;
    display: flex;
  }

  .selector-nav {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 50px;

    .selector-nav-option {
      font-size: 16px;
      line-height: 19px;
      font-weight: 300;
      padding: 15px;
      margin: 0 15px;
      border-bottom: none;
      transition: .3s all ease;
      cursor: pointer;

      &:hover:not(.active) {
        color: #B90B2B;
      }

      &:after {
        content: '';
        display: block;
        margin: auto;
        margin-top: 15px;
        height: 3px;
        width: 0px;
        background: transparent;
        transition: width .5s ease, background-color .5s ease;
      }

      &.active {
        font-weight: 600;
        //border-bottom: 2px solid #000000;
      }

      &.active:after {
        transform: scaleX(1.2);
        width: 100%;
        background: #000000;
      }
    }
  }
}