.circulo-cromatico {
  width: 90%;
  height: 90%;
}

.circulo-path {
  cursor: pointer;
  transition: .3s all ease;
}

.circulo-path.selected {
  -webkit-filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, .5));
  filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, .5));
}