.tendencias-selector-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0  50px;

  .tendencia {
    border: none !important;
    margin: 0;
    width: 100%;
  }

  .tendencia-titulo {
    margin: 20px 0;
  }

  .tendencia-paletas {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  .tendencia-paleta {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 25%;
    transition: .3s all ease;
    margin-right: 22px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.35);
      transform: scale(1.1)
    }

    .tendencia-paleta-image {
      width: 100%;
      height: auto;
      min-height: 100px;
      border-radius: 5px 5px 0 0;
      margin-bottom: 10px;
      background: #dbdada;
    }

    .tendencia-paleta-colors {
      display: flex;
      height: 24px;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0;

      .tendencia-paleta-color {
        height: 24px;
        width: 10%;
        
      }
    }

    .tendencia-paleta-title {
      margin: 5px 0;
      font-size: 16px;
      text-align: center;
      font-weight: 600;
    }
  }
}