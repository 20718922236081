.tendencias-slider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .tendencias-container {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
  }

  .slider-container {
    height: 55%;
  }

  .tendencia-primary-title {
    font-size: 24px;
    font-weight: 400;
    transition: .3s all ease;
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }

  .tendencia-primary-title-shadow {
    position: absolute;
    top: -20px;
    left: 50%;
    font-size: 33px;
    transform: translateX(-50%);
    opacity: 0.2;
  }

  .tendencia-slider-bullet-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .tendencia-slider-bullet {
    width: 0.45rem;
    height: 0.45rem;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
    
    &:hover {
      opacity: 0.7;
    }
  }

  .tendencia-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: absolute;
    top: 35%;
    transform: translate(-50%, -50%);
    width: 15%;
    height: auto;
    transition: .3s .3s all ease,  left .3s, width .3s, box-shadow .1s ease-out;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
    }
    

    &.from-right {
      animation-name: from-right;
      animation-duration: .3s;

      
    }

    &.from-left {
      animation-name: from-left;
      animation-duration: .3s;
    }
    

    .tendencia-card-image {
      width: 100%;
      height: 90px;
      border-radius: 5px 5px 0 0;
    }

    .tendencia-card-colors {
      display: flex;
      height: 10px;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0;

      .tendencia-card-color {
        height: 10px;
        width: 10%;
        
      }
    }

    .tendencia-card-title {
      margin: 5px 0 10px 0;
      font-size: 0.9rem;
      text-align: center;
    }

    &.main {
      width: 60%;
      display: flex;
      height: 50%;
      transition: .3s .1s all ease, left .3s;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      cursor: auto;

      .tendencia-card-image-split {
        width: 55%;
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
      }
      

      .tendencia-card-colors {
        width: 45%;
        display: flex;
        height: 100%;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: flex-start;
        padding-right: 10px;
  
        .tendencia-card-color {
          height: 17%;
          width: 25%;
          margin: 10px 5px;
          border-radius: 3px;
          cursor: pointer;
          transition: .3s all ease;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          text-align: center;
          transition: .3s all ease, height .3s .3s, opacity .3s .6s;
          animation-name: fade-in;
          animation-duration: 1s;
    
      


          .tendencia-card-color-description {
            display: none;
            overflow: hidden;
            font-size: 11px;
            text-transform: uppercase;
            padding: 3px;
          }

          // &:hover {
          //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

          //   .tendencia-card-color-description {
          //     display: block;
          //   }
          // }

          &:hover, &.color-single-selected {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
      
            .tendencia-card-color-description {
              display: block;
            }
          }
        }
      }
      
      .tendencia-card-image-wrapper {
        width: 100%;
        height: 80%;
        background-size: cover;
        border-radius: 10px;       
        border: none;
        background-position: right center;
        // height: auto;
      }

      .tendencia-card-title {
        font-size: 24px;
        text-align: center;
        position: relative;
        margin-top: 15px;

        &:after {
          content: '';
          position: absolute;
          margin-top: 20px;
          bottom: -20px;
          left: 50%;
          width: 5%;
          height: 5px;
          transform: translateX(-50%);
          background: #BF0811;

        }
      }

      
    }
  }
}

@media only screen and (min-width: 1300px) {
  .tendencias-slider {
    
    .tendencia-card {
      width: 13%;
    }
  }
}

@media only screen and (min-width: 1500px) {
  .tendencias-slider {
    
    .tendencia-card {
      width: 12%;
    }
  }
}

@media only screen and (min-width: 1800px) {
  .tendencias-slider {
    
    .tendencia-card {
      width: 10%;
    }
  }
}

@media only screen and (min-width: 2000px) {
  .tendencias-slider {
    
    .tendencia-card {
      width: 8%;
    }
  }
}

@media only screen and (min-height: 1200px) {
  .tendencia-slider-bullet-wrapper {
    margin-top: 30px;
  }
}

@media only screen and (min-height: 1400px) {
  .tendencia-slider-bullet-wrapper {
    margin-top: 50px;
  }
}


@keyframes from-right {
  from {left: 120%}
  to {left: 90%}
}

@keyframes from-left {
  from {left: -20%}
  to {left: 10%}
}

@keyframes fade-in {
  from {opacity: 0}
  to {opacity: 1}
}