.colors-selector-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: 2%;

  .color-selector-primary-title {
    font-size: 24px;
    font-weight: 400;
    transition: .3s all ease;
    position: relative;
    width: 100%;
    text-align: center;

    .color-selector-primary-title-shadow {
      position: absolute;
      top: -20px;
      left: 50%;
      font-size: 33px;
      transform: translateX(-50%);
      opacity: 0.3;
    }
  }

  .colors-selector {
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 60%;
    width: 70%;
    display: flex;
    overflow: hidden;
    
  }

  .circulo-cromatico-wrapper {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    
  }

  .colors-list {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .colors-list-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    font-weight: 600;
    font-size: 16px;

    select {
      margin-left: 10px;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border: none;
      padding: 5px;
      border-radius: 3px;
    }
  }
  

  .color-container-monocromaticos {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 5px;

    
  }

  .color-row {
    width: 100%;
    display: flex;
    flex-shrink: 0;
  }
  
  .color-container-trio {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    padding: 5px;

    .color-single {
      width: 50%;

    }
  }

  .color-col {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .color-single {
    width: calc(100% /7 - 10px);
    height: 45px;
    border-radius: 3px;
    margin: 5px;
    cursor: pointer;
    transition: .3s all ease;
    display: flex;
    justify-content: center;
    align-items: center;


    .color-single-description {
      display: none;
      overflow: hidden;
      font-size: 12px;
      text-transform: uppercase;
      padding: 3px;
    }

    &:hover, &.color-single-selected {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      .color-single-description {
        display: block;
      }
    }
  }

  
}

@media only screen and (max-height: 800px) {
  .colors-selector-wrapper {
    .colors-selector {
      height: 70%;
    }

    .color-single {
      height: 30px;
    }
  }
  
}

@media only screen and (max-width: 1300px) {
  .colors-selector-wrapper {
    .colors-selector {
      width: 80%;
    }

  }
  
}