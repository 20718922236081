.share-modal {
  position: absolute;
  background: #EFEFEF;
  padding: 40px 50px;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-right: 20px solid #B90B2B;
  display: flex;
  flex-direction: column;

  .btn-exit {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .share-modal-title {
    color: #B90B2B;
    font-size: 1.4rem;
    margin-bottom: 40px;
  }

  .share-modal-form {
    display: flex;
    flex-direction: column;
  }

  .share-modal-label {
    margin-bottom: 10px;
    margin-top: 20px;

    &.error {
      color: #E70808;
    }
  }
  .share-modal-input {
    padding: 10px;
    font-size: 1.1rem;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 2px 4px 0px rgb(0,0,0,0.2);

    &.error {
      border: 1px solid #E70808;
    }
  }

  .share-modal-input-error {
    padding: 5px;
    color: #E70808;
    font-size: 0.8em;
  }

  .share-modal-button {
    padding: 10px;
    background: #B90B2B;
    border: none;
    color: #FAFAFA;
    cursor: pointer;
    transition: .3s all ease;
    margin-top: 25px;

    &:hover {
      background: #E70808;
    }

    &:disabled {
      background: rgb(163, 161, 161);
      color: rgb(100,100,100);
      cursor: auto;
    }
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 10px 0;
    flex-direction: column;
  }

  .loader {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border: 3px solid rgba(255,255,255,.8);
    border-radius: 50%;
    border-top-color: #B90B2B;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  .loader-label {
    font-size: 0.8em;
    color: #B90B2B;
    margin-top: 5px;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
}