// @font-face {
//   font-family: 'gotham';
//   src:  url('./fonts/GothamBold.ttf');
//   font-weight: normal;
//   font-style: normal;
// }


.container {
  display: block;
  width: 100%;
  height: 100%;
}

.dimmer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.cursor {
  position: absolute;
  height: 10px;
  width: 10px;
  border: 1px solid #212121;
  z-index: 99999999;
  pointer-events: none;
  transform: translate(-50%, -50%)
}

.inner-container {
  display: flex;
  height: calc(100% - 53px)
}



.workspace {
  flex-basis: 80%;
  background: #232323;
  position: relative;
}

.canvas-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.canvas-paint {
  mix-blend-mode: multiply;
  // opacity: 0;
}


.canvas-result {
  display: none;
  position: absolute;
}

// .canvas-baseimage {
//   opacity: 0;
// }

.canvas-draw {
  cursor: none;

  &.crosshair {
    cursor: crosshair;
  }

  &.grab {
    cursor: move;
  }
}

// COLORBAR

.colorbar {
  flex-basis: 10%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 5%;

  .img-cut {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-height: 800px) {
  .colorbar {
    padding-top: 0;
  }
}

#file-upload {
  display: none;
}
.photo-upload {
  background: #000000;
  border-radius: 3px;
  color: #FFF;
  padding: 20px 30px;
  margin-top: 50px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  transition: .3s all ease;
  cursor: pointer;
  z-index: 50;
  // font-family: "gotham", sans-serif;
  font-size: 14px;
  font-weight: 600;

  span {
    margin-top: 15px;
    line-height: 14px;
  }

  p {
    margin: 0;
    padding: 0;
    position: absolute;
    font-size: 6px;
    opacity: 0;
  }

  &:hover {
    transform: scale(0.9)
  }
}



// TOOLBAR

.toolbar {
  flex-basis: 10%;
  background: #FFFFFF;
  justify-content: center;
  align-items: center;
  box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
}

// GENERICS

.btn-apply-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  .btn-apply-colors {
    width: 20%;
    background: #212121;
    border-radius: 3px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: none;
    cursor: pointer;
    transition: .3s all ease;

    &:hover {
      opacity: 0.7;
    }
  }
}

.notification__content {
  padding: 15px 10px;
}

.notification__message {
  font-size: 16px;
}

@media only screen and (max-width: 1300px) {
  .photo-upload {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .photo-upload {
    width: 90px;
    height: 90px;
    font-size: 12px;
  }
}


