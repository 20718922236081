.tool {
  width: 100%;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  color: #777;
  border-bottom: 1px solid #E5E5E5;
  transition: .3s all ease;
  cursor: pointer;

  

  &.selected {
    box-shadow: 0px 1px 5px -1px rgba(0,0,0,0.25) inset;
    color: #B90B2B;

    svg path {
      fill: #B90B2B;
    }
  }

  &.disabled {
    color: #c5c5c5;

    svg path {
      fill: #c5c5c5;
    }
  }

  .tool-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    p {
      text-align: center;
      margin: 8px;
    }
  }

  .tool-split {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;

    span {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.disabled {
        color: #c5c5c5;
    
        svg path {
          fill: #c5c5c5;
        }
      }
    }
  }

  .tool-expanded {
    width: 100%;
    padding: 5px;
    display: flex;
    background: #FAFAFA;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .tool-expanded-split {
    width: 100%;
    display: flex;

    .tool-expanded-action {
      width: 50%;
      color: #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      transition: .3s all ease;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      svg {
        height: 40px,
      }

      svg path {
        fill: #000;
      }

      &.disabled-expanded {
        color: #ccc;
        cursor: auto;

        svg path, svg circle{
          fill: #CCC;
          stroke: #CCC;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .tool-expanded-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    margin: 5px 0;
    
  }
}


@media only screen and (max-width: 1080px) {
  .tool { 
    .tool-expanded {
      padding: 2px;
      
      .tool-expanded-row {
        margin: 2px 0;
        padding: 0 2px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .tool { 
    .tool-expanded {
      padding: 2px;
      
      .tool-expanded-row {
        margin: 2px 0;
        padding: 0 5%;
      }
    }
  }
}

@media only screen and (max-height: 800px) {
  .tool { 
    padding-top: 12px;

    .tool-icon {
  
      p {
        text-align: center;
        margin: 6px;
      }
    }
  }
}

@media only screen and (max-height: 740px) {
  .tool { 
    padding-top: 10px;

    .tool-icon {
  
      p {
        text-align: center;
        margin: 5px;
      }
    }
  }
}

@media only screen and (max-height: 700px) {
  .tool { 
    padding-top: 7px;

    .tool-icon {
  
      p {
        text-align: center;
        margin: 4px;
      }
    }
  }
}

@media only screen and (max-height: 640px) {
  .tool { 
    padding-top: 6px;

    .tool-icon {
  
      p {
        font-size: 0.75em;
        text-align: center;
        margin: 2px;
      }
    }
  }

}




