.spinner-process-container {
  background: rgba(255,255,255,0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  border: 5px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #B90B2B;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}