@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'gotham';
  src:  url('./fonts/GothamBold.ttf');
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
  font-family: "Barlow", sans-serif;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}


canvas {
  position: absolute;
}

.canvas-wrapper {
  position: relative;
}

.photo-upload p {
  font-family: "gotham", sans-serif;
}
