.stroke {
  cursor: pointer;
  transition: .3s all ease;
  color: #FFFFFF;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:hover {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 960px) {
  .stroke {
    transform: scale(0.8)
  }
}