.color-card {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
  transition: .3s all ease;
  position: relative;

  .color-name {
    max-width: 70%;
    text-align: center;
  }

  &.selected {
    background: #E5E5E5;
    border: 1px solid #BF0811;
    // transform: scale(0.9)
  }

  &:hover {
    opacity: 0.8;
    transform: scale(0.9);
  }

  .color-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;

    .add-color {
      display: flex;
      flex-direction: row;
      width: 70px;
    }
  }

  .color-card-pencil {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}

@media only screen and (max-width: 1300px) {
  .color-card {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .color-card {
    margin-left: 5px;
    margin-right: 5px;
    width: 90px;
    height: 90px;
  }
}