.search-selector-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: 3%;

  h4 {
    width: 70%;
    font-weight: 400;
  }

  .search-selector {
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 60%;
    width: 70%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .search-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;

    p {
      font-weight: 600;
      font-size: 19px;
    }
  }

  .search-color-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
    flex-wrap: wrap;
    padding: 10px 25px;
    overflow-y: scroll;
  }

  .search-box-label {
    width: 100%;
    max-width: 300px;
    position: relative;

    svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-5px, -50%)
    }
  }

  .search-box {
    border: 1px solid #828282;
    border-radius: 22px;
    width: 100%;
    padding: 10px 40px 10px 10px;
    font-size: 16px;
  
    &:focus {
      outline: none;
      border: 1px solid #E70808;
    }
  }

  .color-single {
    width: calc(100% /7 - 10px);
    height: 60px;
    border-radius: 3px;
    margin: 5px;
    cursor: pointer;
    transition: .3s all ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:hover {
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    }

    .color-single-description {
      display: none;
      overflow: hidden;
      font-size: 11px;
      text-transform: uppercase;
      padding: 3px;
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      .color-single-description {
        display: block;
      }
    }
  }

  .search-box-html-label {
    margin-top: 10%;
    width: 60%;
    background: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    border: 1px solid black;

    &:after {
      content: "";
      padding-bottom: 100%;
    }

    input {
      position: absolute;
    }
  }

  .search-box-html {
    background: transparent;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 5px 10px;
    width: 50%;
  }
}